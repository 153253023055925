import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import ArticleListing from '../components/ArticleListing'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import Router from '../components/Router'
import PageTitle from '../components/PageTitle'
import { first } from 'lodash'

class NewsListingTemplate extends Component {
  constructor (props) {
    super(props)
    const normalizedListing = props.data.allWordpressPost.edges.map((article) => {
      const image = article.node.acf.article_images
      const sizes = (image && image[0] && image[0].localFile) ? image[0].localFile.childImageSharp.sizes : null
      return {
        title: article.node.title,
        link: article.node.link,
        content: article.node.content,
        date: article.node.date,
        sizes: sizes
      }
    })
    this.state = {
      articles: normalizedListing
    }
  }
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const cleanedTitle = currentPage.title.replace('&#038;', '&').replace('&#8211;', '&')

    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${cleanedTitle}`} />
        }
        <Router
          items={first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items}
        />
        <PageTitle title={cleanedTitle} prefix />
        <ArticleListing items={this.state.articles} />

        <Stripe top />
        <Stripe filter />

        <Footer />
      </Layout>
    )
  }
}

export default NewsListingTemplate

export const NewsListingQuery = graphql`
  query newsListingQuery($id: String!) {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    allWordpressPost(sort: {fields: [date], order: DESC}) {
        edges {
          node {
            title
            link
            content
            date
            categories {
                name
            }
            acf {
                article_images {
                    localFile {
                        childImageSharp {
                            sizes(
                                maxWidth: 1500,
                                maxHeight: 1500,
                                cropFocus: CENTER
                            ) {
                                ...GatsbyImageSharpSizes_withWebp
                            }
                        }
                    }
                }
            }
          }
        }
      }
      wordpressPage(id: { eq: $id }) {
        title
        content
        date(formatString: "MMMM DD, YYYY")
        acf {
          seo_page_title
        }
      }
      site {
        id
        siteMetadata {
          title
        }
      }
    }
`
