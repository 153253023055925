import styled from 'styled-components'
import Link from '../../components/Link'
import { Box, Text } from 'rebass'
import { media, typeface, easeOutExpo, StyledLinks, RouterRollOver } from '../../theme/'

export const ArticlesContainer = styled(Box)`
  position: relative;
`

export const ItemLink = styled(Link)`
  text-decoration: none;
  &:hover .highlight {
    color: ${RouterRollOver};
    cursor: pointer;
    transition: color 0.3s ${easeOutExpo};
  }

  .gatsby-image-wrapper {
    z-index: 1;
    width: auto;
    ${media.LargeScreenUp`
      width: auto;
      min-width: ${props => props.small ? '217px' : '260px'};
    `}
    height: auto;
  }
`

export const Title = styled(Text)`
  font-family: ${typeface.sans};
  font-weight: 700;
  color: white;
`

export const Attribution = styled(Text)`
  font-weight: 700;
  font-style: italic;
  color: rgba(226, 222, 221, 1);
  letter-spacing: 0.05em;
`

export const ReleasedDate = styled(Text)`
  font-style: italic;
  letter-spacing: 0.05em;
  opacity: 0.52;
`

export const Item = styled(Box)`
  z-index: 2;
  position: relative;
`

export const Filters = styled(Box)`
  z-index: 1;
  display: none;
  ${StyledLinks}
  ${media.LargeScreenUp`
    display: block;
  `}
`
